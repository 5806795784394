import React, { useEffect, useState } from 'react';
import './Homepage.css';
import Bottom from '../../components/Bottom/Bottom';
// import Bhagwanji from '../../assets/Bhagwanji.png';
import SitaramImage from '../../assets/sitaram.png';
import Navbar from '../../components/Navbar/Navbar';
import FooterWithLinks from '../../components/FooterWithLinks/FooterWithLinks';
import maSavitriLogo from '../../assets/mashavitri logo.png';
import { Link } from 'react-router-dom';

const Homepage = () => {
  const [userCount, setUserCount] = useState(0);
  const [mantraCount, setMantraCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/user/count`
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setUserCount(data.data.count);
      } catch (error) {
        console.error('Error fetching user count:', error);
      }
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/mantralekhan/count`
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setMantraCount(data.data.count);
      } catch (error) {
        console.error('Error fetching user count:', error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="home-main">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-sm-4 col-12 p-0 overflow-hidden">
            <img src={SitaramImage} alt="Your Image" className="img-fluid" />
          </div>
          <div className="col-sm-4 col-12 py-3 px-3">
            <div className="bg-white shadow-lg rounded-lg overflow-hidden p-3">
              <p className="text-xl">
                Total <span className="sinduri">{mantraCount}</span>{' '}
                Mantralekhan By <span className="sinduri">{userCount}</span>{' '}
               
                Registered Users On This Site And Counting
                <span class="blinking-dots">.</span>
              </p>
            </div>
            <br />
            <div className="middle-stump my-3">
              <Link to={'/mantrapage'}>
                <button className="button">Start Mantralekhan</button>
              </Link>
            </div>
            <h5>
              Discover the Power of the{' '}
              <span style={{ color: '#de4620' }}>SITARAM</span> Mantra
            </h5>
            <br />
            The <span style={{ color: '#de4620' }}>SITARAM</span> Mantra
            transcends the limits of human understanding, embodying profound
            spiritual truths. <span style={{ color: '#de4620' }}>Ram</span>{' '}
            represents the universal consciousness—both within and around
            us—symbolizing masculine energy and truth. In contrast,{' '}
            <span style={{ color: '#de4620' }}>Sita</span> embodies the Shakti
            of the universe, the divine feminine energy and power.
            <br /> <br />
            The union of these two energies ignites the enlightenment of{' '}
            <span style={{ color: '#de4620' }}>Kundalini Shakti</span>.{' '}
            <span style={{ color: '#de4620' }}>Ram</span>, known as{' '}
            <span style={{ color: '#de4620' }}>Maryada Purushottam</span>,
            epitomizes the ideal man and serves as the{' '}
            <span style={{ color: '#de4620' }}>Beeja Mantra</span> for the{' '}
            <span style={{ color: '#de4620' }}>Manipur Chakra</span> (Solar
            Plexus). <span style={{ color: '#de4620' }}>Sita</span>, often
            referred to as the daughter of the Earth, is intimately connected
            with the <span style={{ color: '#de4620' }}>Muladhara Chakra</span>{' '}
            (Root Chakra).
            <br /> <br />
            By chanting and writing "
            <span style={{ color: '#de4620' }}>SITARAM</span>," you can ignite
            your inner fire, cleansing physical, mental, and karmic impurities.
            This practice enhances your willpower, clarifies your goals, and
            cultivates the wisdom needed to navigate life’s journey. It fosters
            health, wealth, and abundance—blessings of the{' '}
            <span style={{ color: '#de4620' }}>Muladhara Chakra</span>.
            <br /> <br />
            Writing "<span style={{ color: '#de4620' }}>SITARAM</span>"
            resonates through all the{' '}
            <span style={{ color: '#de4620' }}>Nadis</span> and{' '}
            <span style={{ color: '#de4620' }}>Chakras</span> in your body,
            releasing toxins and restoring hormonal balance. As you chant, you
            enter a profound state of peace and relaxation, dissolving habitual
            stress and tension. Negative karma dissipates, allowing your inner
            bliss to flourish.
            <br /> <br />
            Consistent and disciplined chanting of "
            <span style={{ color: '#de4620' }}>SITARAM</span>" revitalizes your
            spirit, diminishing the ego and enveloping you in protective
            vibrations. This mantra shields you from negativity, psychic
            attacks, and potential harm. The transformative power of the{' '}
            <span style={{ color: '#de4620' }}>SITARAM</span> Mantra can
            profoundly alter your being, radiating peace and inner bliss that
            manifests as <span style={{ color: '#de4620' }}>Tejas</span> or
            spiritual glow.
            <br /> <br />
            Experience improved physical and mental health, harmonious
            relationships, and a deeper connection with your inner self and the
            divine. Embrace the fulfillment that the{' '}
            <span style={{ color: '#de4620' }}>SITARAM</span> Mantra brings to
            your life.
          </div>
          <div className="col-sm-4 col-12 p-5">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                src={maSavitriLogo}
                style={{ maxWidth: '200px', height: 'auto' }}
              />
              <div className="bg-white shadow-lg rounded-lg overflow-hidden p-3 mt-1">
                <div>
                  <p>
                    It has been decided to encourage the writers through the
                    institute for writing Sitaram Mantra, which is as follows:
                  </p>
                  <ul className="list-disc">
                    <li>
                      The person who writes Sitaram Mantra{' '}
                      <span className="sinduri">51,000</span> times within a
                      month from the date of registration will get a
                      <span className="sinduri">
                        {' '}
                        free Jyotish consultation
                      </span>
                      .
                    </li>
                    <li>
                      The person who writes Sitaram Mantra{' '}
                      <span className="sinduri">111,000</span> times within a
                      month or before from the date of registration will get his{' '}
                      <span className="sinduri">
                        Sampurna Jeevan Darpan for free
                      </span>
                      .
                    </li>
                    <li>
                      The person who writes Sitaram Mantra{' '}
                      <span className="sinduri">1,100,000</span> times within
                      one year or before from the date of registration will get
                      the opportunity to become Yajman{' '}
                      <span className="sinduri">
                        absolutely free for Kalsarp Yog and Grah Shanti Yagya
                      </span>{' '}
                      organized annually by the trust on the occasion of
                      Nagpanchami.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="homepage-container">
        <div className="image-container">
          <img src={SitaramImage} alt="Your Image" className="homepage-image" />
        </div>
        <div className="text-container">
          <p>
            {' '}
            This is an endeavour to please Bhagwan Swaminarayan by typing
            “Swaminarayan” mahamantra online.
          </p>
          <br />
          <p className="ltext">
            Total <span>{mantraCount}</span> Mantralekhan By{' '}
            <span>{userCount}</span> Registered Users On This Site And Counting
            ...
          </p>
          <button className="homepage-button">Learn More</button>
        </div>
      </div> */}
      <FooterWithLinks />
    </div>
  );
};

export default Homepage;
